import * as React from "react";
import { graphql } from "gatsby";
import dayjs from "dayjs";
import { getImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import {
  Button,
  Collapse,
  Heading,
  Link,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { CalendarIcon, ExternalLinkIcon } from "@chakra-ui/icons";

import Layout from "../components/Layout";
import WorkCard from "../components/WorkCard";
import Gallery from "../components/Gallery";
// import FlipbookPDF from "../components/Flipbook";

import { downloadIcs } from "../helpers";
import documentToReactComponentsCustom from "../ctfTextToReact";

import { ExhibitionI } from "../interfaces";

const ImageWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  color: #fff;

  @media (min-width: 420px) {
    width: 75%;
  }
`;

const Exhibition = ({
  data: {
    contentfulExhibition: {
      node_locale,
      title,
      description,
      slug,
      dateStart,
      dateEnd,
      cover,
      media,
      text,
      address,
      googleMapsUrl,
      locationInfo,
      work,
      participants,
      catalog,
      catalogAvailable,
    },
  },
  pageContext: { language, localeSwitcher },
  location,
}: {
  data: { contentfulExhibition: ExhibitionI };
  location: { pathname: string };
  pageContext: {
    language: string;
    localeSwitcher: { [key: string]: string };
  };
}) => {
  const { isOpen, onToggle } = useDisclosure();
  const [showThirdPartyDescription, setShowThirdPartyDescription] =
    React.useState(false);

  return (
    <Layout
      title={title}
      description={description}
      locale={node_locale}
      location={location}
      limitWidth
      localeSwitcher={localeSwitcher}
    >
      <ImageWrapper>
        <Gallery media={cover} />
        <Heading as="h1" paddingBottom={0}>
          {title}
        </Heading>
        <Text padding="0.66em 0">{description}</Text>
        <Text>
          <Tooltip label="Zum Kalender hinzufügen" placement="right">
            <Link
              onClick={() =>
                downloadIcs({ title, description, dateStart, address })
              }
            >
              {dayjs(dateStart).format("DD.MM.YYYY")} -{" "}
              {dayjs(dateEnd).format("DD.MM.YYYY")} <CalendarIcon mx="2px" />
            </Link>
          </Tooltip>
        </Text>

        {address && (
          <Text margin="0 0 0.65em 0">
            {googleMapsUrl ? (
              <Tooltip label="In Google Maps öffnen" placement="right">
                <Link isExternal href={googleMapsUrl}>
                  {address} <ExternalLinkIcon mx="1px" />
                </Link>
              </Tooltip>
            ) : (
              address
            )}
          </Text>
        )}

        {locationInfo?.raw && (
          <div style={{ margin: "0 0 0.65em 0" }}>
            {documentToReactComponentsCustom(locationInfo)}
          </div>
        )}

        {participants?.length && (
          <>
            <Collapse in={!isOpen} animateOpacity>
              {language == "de" ? "Teilnehmer" : "Participants"}:{" "}
              {participants.slice(0, 3).map((participant, i) => {
                if (participants.slice(0, 3).length - 1 === i)
                  return `${participant}...`;
                return `${participant}, `;
              })}
              {participants.length > 2 && (
                <Button onClick={onToggle} size="xs" variant="ghost">
                  {language == "de" ? "Alle anzeigen" : "Show all"}
                </Button>
              )}
            </Collapse>
            {participants.length > 2 && (
              <>
                <Collapse in={isOpen} animateOpacity>
                  {language == "de" ? "Teilnehmer" : "Participants"}:{" "}
                  {participants.map((participant) => {
                    return `${participant}${
                      participants[participants.length - 1] === participant
                        ? ""
                        : ", "
                    }`;
                  })}
                </Collapse>
              </>
            )}
          </>
        )}

        {catalogAvailable && catalog?.file.url && (
          <Link isExternal href={catalog.file.url}>
            {language == "de" ? "Katalog" : "Catalogue"}{" "}
            <ExternalLinkIcon mx="1px" />
          </Link>
        )}
      </ImageWrapper>

      {text?.raw && (
        <div style={{ marginTop: "3em" }}>
          {documentToReactComponentsCustom(text)}
        </div>
      )}

      {/* {catalogAvailable && catalog?.file.url && (
        <FlipbookPDF url={catalog.file.url} />
      )} */}

      {media?.length && <Gallery media={media} />}

      {work?.length && (
        <div style={{ maxWidth: "50%", margin: "3.5em auto 0" }}>
          <Heading as="h3">
            {language == "de" ? "Ausgestelltes Werk" : "Exhibited Work"}
          </Heading>
          <br />
          {work?.map((work) => (
            <WorkCard key={work.slug} data={work} locale={node_locale} />
          ))}
        </div>
      )}
    </Layout>
  );
};

export default Exhibition;

export const pageQuery = graphql`
  query ExhibitionQuery($contentful_id: String!, $language: String!) {
    contentfulExhibition(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $language }
    ) {
      ...ContentfulExhibitionFragment
    }
  }
`;
